
import { Vue, Component } from 'vue-property-decorator'
import { PageHeader } from '@/components/page-header'
import { Card } from '@/components/card'
@Component({
  components: {
    PageHeader,
    Card,
  },
})
export default class UnauthorizedAccess extends Vue {

}
